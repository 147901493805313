import {
  Api as EssentialScoresApi,
  Redux as EssentialScoresRedux,
} from 'sportsbook-essential-scores';
import actionCreators from 'SportsbookOverview/redux/action_creators';

export default ({ eventIds }) => (dispatch) => {
  dispatch(actionCreators.fetchEssentialScoresInit());

  EssentialScoresApi.fetchEssentialScores({
    eventIds,
  }).then((essentialScoresResponse) => {
    dispatch(EssentialScoresRedux.actionCreators.upsertEssentialScores(essentialScoresResponse));
    dispatch(actionCreators.fetchEssentialScoresSuccess());
  });
};
