import { v as bvVar } from 'bv';
import { getJSON } from 'bv-fetch';
import { locale } from 'bv-i18n';

export default ({
  eventIds,
  marketTypeDimension,
  periodDimension,
  boosted,
  outcomesPerEvent,
  couponOutcomeKeys,
  ignoreOutcomeKeys,
}) => getJSON(`/bv_api/${locale()}/${bvVar('brandId')}/overview/markets`, {
  event_ids: eventIds.join(','),
  market_type_dimension: marketTypeDimension,
  period_dimension: periodDimension,
  outcomes_per_event: outcomesPerEvent,
  outcome_keys: (couponOutcomeKeys || []).join(','),
  ignore_outcome_keys: ignoreOutcomeKeys,
  boosted,
});
