import { useContext } from 'react';
import PropTypes from 'prop-types';
import { ConfigContext } from 'SportsbookOverview/contexts';
import SeeMoreMarkets from './template_c/see_more_markets_redux';

const TemplateC = ({
  eventId,
  marketId,
  startTime,
  eventTimeComponent,
  eventDetailComponent,
  marketComponent,
}) => {
  const { showMarketDescription } = useContext(ConfigContext);

  const EventTime = eventTimeComponent;
  const EventDetail = eventDetailComponent;
  const Market = marketComponent;

  return (
    <>
      <div data-event-id={eventId} data-event-date={startTime} className="inplay-coupon-row">
        <EventTime eventId={eventId} />
        <EventDetail
          eventId={eventId}
          marketId={marketId}
          showMarketDescription={showMarketDescription}
        />
        <SeeMoreMarkets eventId={eventId} marketId={marketId} />
      </div>
      <Market eventId={eventId} marketId={marketId} />
    </>
  );
};

TemplateC.propTypes = {
  eventId: PropTypes.number.isRequired,
  marketId: PropTypes.number,
  startTime: PropTypes.string.isRequired,
  eventTimeComponent: PropTypes.node.isRequired,
  eventDetailComponent: PropTypes.node.isRequired,
  marketComponent: PropTypes.node.isRequired,
};

TemplateC.defaultProps = {
  marketId: null,
};

export default TemplateC;
