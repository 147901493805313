import PropTypes from 'prop-types';
import SuspendedView from './suspended_view';

const SuspendedContainer = ({ suspensionReasons, scoreboard }) => {
  const isCurrentReason = (reason) => reason.type.toLowerCase()
    === scoreboard.marketSuspensionReason.toLowerCase();

  const suspensionReason = (
    scoreboard.marketSuspensionReason && suspensionReasons.find(isCurrentReason)
  ) || { description: undefined };

  return (
    <SuspendedView
      suspensionReasonDescription={suspensionReason.description}
    />
  );
};

SuspendedContainer.propTypes = {
  suspensionReasons: PropTypes.instanceOf(Array),
  scoreboard: PropTypes.instanceOf(Object),
};

SuspendedContainer.defaultProps = {
  suspensionReasons: [],
  scoreboard: {},
};

export default SuspendedContainer;
