import PropTypes from 'prop-types';
import EventComponent from 'SportsbookOverview/components/link_types/shared/boosts/event';
import Outcomes from 'SportsbookCommon/bet_boost/outcomes/outcomes_redux';

const Event = ({ eventId }) => (
  <EventComponent
    eventId={eventId}
    outcomesComponent={Outcomes}
    showMore={false}
  />
);

Event.propTypes = {
  eventId: PropTypes.number.isRequired,
};

export default Event;
