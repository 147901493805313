import { useDispatch } from 'react-redux';
import { compose } from 'underscore';
import { get, set } from 'bv-local-storage';
import Redux from 'SportsbookOverview/redux';

const LOCAL_STORAGE_KEY = 'overview-sorting';

export const useSetSorting = () => {
  const dispatch = useDispatch();
  const setSorting = compose(dispatch, Redux.actionCreators.setSorting);

  return (opts) => {
    set(LOCAL_STORAGE_KEY, { ...get(LOCAL_STORAGE_KEY), ...opts });
    setSorting(opts);
  };
};
