import { useState, useContext } from 'react';
import PropTypes from 'prop-types';
import { ConfigContext } from 'SportsbookOverview/contexts';
import LinkTypes from 'SportsbookOverview/components/link_types';
import EventsGroupView from './events_group_view';

const EventsGroupContainer = ({ eventsGroup, useAmericanFormat }) => {
  const [seeAllEvents, setSeeAllEvents] = useState(false);

  const config = useContext(ConfigContext);

  const {
    hideGroupTitle,
    eventsPerComp,
    linkType,
  } = config;

  const eventIds = eventsGroup.children;
  const initialVisibleEvents = eventIds.slice(0, eventsPerComp);
  const allOtherEvents = eventIds.slice(eventsPerComp);

  const linkTypesComponents = LinkTypes[linkType ? linkType.value : 'overview'];

  return (
    <EventsGroupView
      groupTitle={eventsGroup.description}
      hideGroupTitle={hideGroupTitle}
      eventIds={eventIds}
      initialVisibleEvents={initialVisibleEvents}
      allOtherEvents={allOtherEvents}
      seeAllEvents={seeAllEvents}
      expandAllEvents={() => setSeeAllEvents(true)}
      eventsGroupHeaderComponent={linkTypesComponents.EventsGroupHeader}
      eventComponent={linkTypesComponents.Event}
      useAmericanFormat={useAmericanFormat}
    />
  );
};

EventsGroupContainer.propTypes = {
  eventsGroup: PropTypes.instanceOf(Object).isRequired,
  useAmericanFormat: PropTypes.bool.isRequired,
};

export default EventsGroupContainer;
