import {
  intersection, omit, uniq,
} from 'underscore';
import {
  INSERT_EVENTS,
  REMOVE_EVENTS,
  SET_EVENTS_ACTIVE_MARKET_INIT,
  SET_EVENTS_ACTIVE_MARKET,
  REMOVE_EVENTS_ACTIVE_MARKET,
  SET_CARDS,
  REMOVE_CARDS,
  SET_GROUPS_BY_CARD,
  REMOVE_GROUPS_BY_CARD,
  SET_SORTING,
  TOGGLE_CARD_EXPANDED,
  SET_CARD_HAS_MARKETS,
  SET_EVENT_FILTERS,
  REMOVE_EVENT_FILTERS,
} from './action_types';

const initialState = {
  eventIds: [],
  eventsActiveMarket: {},
  eventFilters: {},
  cards: [],
  groupsByCard: {},
  sorting: {},
};

export default (state = initialState, action = {}) => {
  switch (action.type) {
    case INSERT_EVENTS: {
      const { eventIds } = action;

      return {
        ...state,
        eventIds: uniq([...state.eventIds, ...eventIds.map((eventId) => parseInt(eventId, 10))]),
      };
    }

    case REMOVE_EVENTS: {
      const { eventIds } = action;

      return {
        ...state,
        eventIds: state.eventIds.filter((id) => eventIds.includes(id)),
      };
    }

    case SET_CARDS: {
      const { cards } = action;

      return {
        ...state,
        cards: cards.map((card) => ({ ...card, hasMarkets: false })),
      };
    }

    case REMOVE_CARDS: {
      return {
        ...state,
        cards: [],
      };
    }

    case SET_EVENT_FILTERS: {
      const { eventFilters: { labels }, eventFilters, expanded } = action;
      let expandedCardIndex = expanded;

      return {
        ...state,
        eventFilters,
        cards: state.cards.map((card, index) => {
          const hidden = labels.length && !intersection(labels, card.eventLabels).length;

          if (hidden) expandedCardIndex += 1;

          return {
            ...card,
            expanded: card.hidden && !hidden ? index < expandedCardIndex : card.expanded,
            hidden,
          };
        }),
      };
    }

    case REMOVE_EVENT_FILTERS: {
      return {
        ...state,
        eventFilters: {},
      };
    }

    case SET_GROUPS_BY_CARD: {
      const { groupsByCard } = action;

      return {
        ...state,
        groupsByCard,
      };
    }

    case REMOVE_GROUPS_BY_CARD: {
      return {
        ...state,
        groupsByCard: {},
      };
    }

    case SET_EVENTS_ACTIVE_MARKET_INIT: {
      const { eventIds } = action;

      return {
        ...state,
        eventsActiveMarket: {
          ...state.eventsActiveMarket,
          ...eventIds.reduce((acc, eventId) => ({ ...acc, [eventId]: undefined }), {}),
        },
      };
    }

    case SET_EVENTS_ACTIVE_MARKET: {
      const { activeMarketMapping } = action;

      return {
        ...state,
        eventsActiveMarket: {
          ...state.eventsActiveMarket,
          ...activeMarketMapping,
        },
      };
    }

    case REMOVE_EVENTS_ACTIVE_MARKET: {
      const { eventIds } = action;

      return {
        ...state,
        eventsActiveMarket: omit(state.eventsActiveMarket, eventIds),
      };
    }

    case SET_SORTING: {
      const { method, direction } = action;

      return {
        ...state,
        sorting: {
          method: method || (state.sorting && state.sorting.method),
          direction: direction || (state.sorting && state.sorting.direction),
        },
      };
    }

    case TOGGLE_CARD_EXPANDED: {
      return {
        ...state,
        cards: [
          ...state.cards.map((c) => (c.id === action.cardId ? { ...c, expanded: !c.expanded } : c)),
        ],
      };
    }

    case SET_CARD_HAS_MARKETS: {
      return {
        ...state,
        cards: [
          ...state.cards.map((c) => (c.id === action.cardId ? { ...c, hasMarkets: true } : c)),
        ],
      };
    }

    default:
      return state;
  }
};
