import { useMemo, useContext } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { isEqual } from 'underscore';
import Redux from 'SportsbookOverview/redux';
import { ConfigContext } from 'SportsbookOverview/contexts';
import ScoreHeadersContainer from './score_headers_container';

const ScoreHeadersRedux = ({ eventIds }) => {
  // Stablish redux selector to extract if any of the eventIds are in Play
  const getEventIdsAreInPlay = useMemo(Redux.selectors.makeGetEventIdsAreInPlay, []);
  const areInPlay = useSelector((state) => getEventIdsAreInPlay(state, { eventIds }), isEqual);
  // --------------------------------------------

  // Stablish redux selector to extract the event Ids sport Id
  const getEventIdsSportId = useMemo(Redux.selectors.makeGetEventIdsSportId, []);
  const sportId = useSelector((state) => getEventIdsSportId(state, { eventIds }), isEqual);
  // --------------------------------------------

  const { couponType } = useContext(ConfigContext);

  return (
    <ScoreHeadersContainer
      areInPlay={areInPlay}
      sportId={sportId}
      couponType={couponType.value}
    />
  );
};

ScoreHeadersRedux.propTypes = {
  eventIds: PropTypes.instanceOf(Array).isRequired,
};

export default ScoreHeadersRedux;
