import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { sorting } from 'SportsbookOverview/services';
import Redux from 'SportsbookOverview/redux';
import ModalContainer from './modal_container';
import { useSetSorting } from '../hooks/use_set_sorting';

const ModalRedux = ({ onCloseClick }) => {
  // Stablish redux setSortingMethod method
  const setSorting = useSetSorting();
  // --------------------------------------------

  // ----- Extract list of sorting methods
  const sortingMethods = useSelector(sorting);
  // ----------------------------------------

  // Stablish redux selector to extract the sorting method
  const activeSorting = useSelector(Redux.selectors.getSorting);
  // --------------------------------------------

  return (
    <ModalContainer
      onCloseClick={onCloseClick}
      setSorting={setSorting}
      sortingMethods={sortingMethods}
      activeSortingMethod={activeSorting.method}
    />
  );
};

ModalRedux.propTypes = {
  onCloseClick: PropTypes.func.isRequired,
};

export default ModalRedux;
