import PropTypes from 'prop-types';
import { EventsGroup } from '../events_group';

const EventsGroupsView = ({
  cardId,
  cardHasMarkets,
  eventsGroups,
}) => eventsGroups.map((eventsGroup) => (
  <EventsGroup
    key={`${eventsGroup.day}-${eventsGroup.competitionUrl}-${eventsGroup.children.join('-')}`}
    cardId={cardId}
    cardHasMarkets={cardHasMarkets}
    eventsGroup={eventsGroup}
  />
));

EventsGroupsView.propTypes = {
  cardId: PropTypes.string.isRequired,
  cardHasMarkets: PropTypes.bool.isRequired,
  eventsGroups: PropTypes.instanceOf(Array).isRequired,
};

export default EventsGroupsView;
