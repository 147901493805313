import { removeEvents as sportsbookRemoveEvents } from 'sportsbook-events-duck';
import { Redux as EssentialScoresRedux } from 'sportsbook-essential-scores';
import { flatten, property } from 'underscore';
import { removeMarkets } from 'sportsbook-markets-duck';
import { makeGetMarketOutcomes } from 'sportsbook-markets-selector';
import { removeOutcomes } from 'sportsbook-outcomes-duck';
import selectors from '../selectors';
import actionCreators from '../action_creators';

const getMarketOutcomes = makeGetMarketOutcomes();

const {
  getEvents,
  getEventsActiveMarket,
} = selectors;

const {
  removeEvents,
  removeCards,
  removeGroupsByCard,
  removeEventsActiveMarket,
  removeEventFilters,
} = actionCreators;

export default () => (dispatch, getState) => {
  const eventIds = Object.keys(getEvents(getState()));
  const marketIds = Object.values(getEventsActiveMarket(getState()));
  const outcomeIds = flatten(
    marketIds.map((marketId) => getMarketOutcomes(getState(), { marketId })),
  ).map(property('id'));

  dispatch(removeCards());
  dispatch(removeGroupsByCard());
  dispatch(removeEventsActiveMarket(eventIds));
  dispatch(removeEventFilters());
  dispatch(removeEvents(eventIds));
  dispatch(sportsbookRemoveEvents(eventIds));
  dispatch(removeMarkets(marketIds));
  dispatch(removeOutcomes(outcomeIds));
  dispatch(EssentialScoresRedux.actionCreators.removeEssentialScores(eventIds));
};
