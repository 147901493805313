import { useContext } from 'react';
import PropTypes from 'prop-types';
import { ConfigContext } from 'SportsbookOverview/contexts';

const TemplateDefault = ({
  eventId,
  marketId,
  startTime,
  eventCommentComponent: EventComment,
  eventDetailComponent: EventDetail,
  marketComponent: Market,
}) => {
  const { showAllEventMarkets } = useContext(ConfigContext);

  return (
    <div data-event-id={eventId} data-event-date={startTime} className="inplay-coupon-row inplay">
      <EventComment eventId={eventId} />
      <EventDetail
        showClock
        eventId={eventId}
        marketId={marketId}
        showMore={!showAllEventMarkets}
      />
      <Market eventId={eventId} marketId={marketId} />
    </div>
  );
};

TemplateDefault.propTypes = {
  eventId: PropTypes.number.isRequired,
  marketId: PropTypes.number.isRequired,
  startTime: PropTypes.string.isRequired,
  eventCommentComponent: PropTypes.node.isRequired,
  eventDetailComponent: PropTypes.node.isRequired,
  marketComponent: PropTypes.node.isRequired,
};

export default TemplateDefault;
