import PropTypes from 'prop-types';
import { betBuilderHref } from 'SportsbookOverview/services';
import EventView from './event_view';

const EventContainer = ({ eventId }) => (
  <EventView
    eventId={eventId}
    eventHref={betBuilderHref(eventId)}
  />
);

EventContainer.propTypes = {
  eventId: PropTypes.number.isRequired,
};

export default EventContainer;
