import PropTypes from 'prop-types';
import { eventHref } from 'SportsbookOverview/services';
import ShowMoreView from './show_more_view';

const ShowMoreContainer = ({
  eventId, sportEventPathId, meetingId, labelText,
}) => (
  <ShowMoreView
    eventHref={eventHref(sportEventPathId, meetingId, eventId)}
    labelText={labelText}
  />
);

ShowMoreContainer.propTypes = {
  eventId: PropTypes.number.isRequired,
  sportEventPathId: PropTypes.number,
  meetingId: PropTypes.number.isRequired,
  labelText: PropTypes.string.isRequired,
};

ShowMoreContainer.defaultProps = {
  sportEventPathId: 240,
};

export default ShowMoreContainer;
