export const INSERT_EVENTS = 'sportsbookOverview/INSERT_EVENTS';
export const REMOVE_EVENTS = 'sportsbookOverview/REMOVE_EVENTS';
export const SET_EVENTS_ACTIVE_MARKET_INIT = 'sportsbookOverview/SET_EVENTS_ACTIVE_MARKET_INIT';
export const SET_EVENTS_ACTIVE_MARKET = 'sportsbookOverview/SET_EVENTS_ACTIVE_MARKET';
export const REMOVE_EVENTS_ACTIVE_MARKET = 'sportsbookOverview/REMOVE_EVENTS_ACTIVE_MARKET';
export const FETCH_ESSENTIAL_SCORES_INIT = 'sportsbookOverview/FETCH_ESSENTIAL_SCORES_INIT';
export const FETCH_ESSENTIAL_SCORES_SUCCESS = 'sportsbookOverview/FETCH_ESSENTIAL_SCORES_SUCCESS';
export const FETCH_MARKETS_INIT = 'sportsbookOverview/FETCH_MARKETS_INIT';
export const FETCH_MARKETS_SUCCESS = 'sportsbookOverview/FETCH_MARKETS_SUCCESS';
export const SET_CARDS = 'sportsbookOverview/SET_CARDS';
export const SET_GROUPS_BY_CARD = 'sportsbookOverview/SET_GROUPS_BY_CARD';
export const SET_SORTING = 'sportsbookOverview/SET_SORTING';
export const REMOVE_CARDS = 'sportsbookOverview/REMOVE_CARDS';
export const REMOVE_GROUPS_BY_CARD = 'sportsbookOverview/REMOVE_GROUPS_BY_CARD';
export const TOGGLE_CARD_EXPANDED = 'sportsbookOverview/TOGGLE_CARD_EXPANDED';
export const SET_CARD_HAS_MARKETS = 'sportsbookOverview/SET_CARD_HAS_MARKETS';
export const RESET_STATE_SUCCESS = 'sportsbookOverview/RESET_STATE_SUCCESS';
export const SET_EVENT_FILTERS = 'sportsbookOverview/SET_EVENT_FILTERS';
export const REMOVE_EVENT_FILTERS = 'sportsbookOverview/REMOVE_EVENT_FILTERS';
