import { useContext } from 'react';
import PropTypes from 'prop-types';
import { TemplateContext } from 'SportsbookOverview/contexts';
import { eventHref } from 'SportsbookOverview/services';
import MarketView from './market_view';

const MarketContainer = ({
  eventId, marketId, sportEventPathId, meetingId, outcomeIds,
}) => {
  const { outcomeComponent } = useContext(TemplateContext);

  return (
    <MarketView
      eventId={eventId}
      marketId={marketId}
      outcomeIds={outcomeIds}
      eventHref={eventHref(sportEventPathId, meetingId, eventId)}
      outcomeComponent={outcomeComponent}
    />
  );
};

MarketContainer.propTypes = {
  eventId: PropTypes.number.isRequired,
  marketId: PropTypes.number,
  sportEventPathId: PropTypes.number,
  meetingId: PropTypes.number.isRequired,
  outcomeIds: PropTypes.instanceOf(Array).isRequired,
};

// The BE is not returning this field, before opening
// this to more sports it would need to return it
MarketContainer.defaultProps = {
  marketId: null,
  sportEventPathId: 240,
};

export default MarketContainer;
