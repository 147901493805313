import PropTypes from 'prop-types';
import { SeeMoreMarkets } from 'SportsbookOverview/components/see_more_markets';

const SeeMoreMarketsView = ({ eventId, hasMarket, couponHeaders }) => (!hasMarket ? (
  <div className={`inplay-coupon-odds-column inplay-headers-count-${couponHeaders?.length}`}>
    <SeeMoreMarkets eventId={eventId} />
  </div>
) : null);

SeeMoreMarketsView.propTypes = {
  eventId: PropTypes.number.isRequired,
  hasMarket: PropTypes.bool.isRequired,
  couponHeaders: PropTypes.instanceOf(Array).isRequired,
};

export default SeeMoreMarketsView;
