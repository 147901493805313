import PropTypes from 'prop-types';
import { shouldApplyAmericanFormat } from 'sportsbook-helpers';

const MarketView = ({
  eventId,
  marketId,
  marketAbsent,
  marketSuspended,
  seeMoreMarketsComponent,
  suspendedComponent,
  outcomeComponent,
  outcomeIds,
  couponHeaders,
  americanFormat,
  outcomes,
}) => {
  const SeeMoreMarkets = seeMoreMarketsComponent;
  const Suspended = suspendedComponent;
  const Outcome = outcomeComponent;

  const displayOutcomeIds = shouldApplyAmericanFormat(americanFormat, outcomes)
    ? outcomeIds.toReversed()
    : outcomeIds;

  return (
    <div data-market-id={marketId} className={`inplay-coupon-odds-column inplay-headers-count-${couponHeaders?.length}`}>
      {
        marketAbsent && (
          <SeeMoreMarkets eventId={eventId} />
        )
      }
      {
        marketSuspended && (
          <Suspended eventId={eventId} />
        )
      }
      {
        !marketAbsent && !marketSuspended && (
          displayOutcomeIds.map((outcomeId) => (
            <Outcome outcomeId={outcomeId} />
          ))
        )
      }
    </div>
  );
};

MarketView.propTypes = {
  eventId: PropTypes.number.isRequired,
  marketId: PropTypes.number.isRequired,
  marketAbsent: PropTypes.bool.isRequired,
  marketSuspended: PropTypes.bool.isRequired,
  seeMoreMarketsComponent: PropTypes.node.isRequired,
  suspendedComponent: PropTypes.node.isRequired,
  outcomeComponent: PropTypes.node.isRequired,
  outcomeIds: PropTypes.instanceOf(Array).isRequired,
  couponHeaders: PropTypes.instanceOf(Array).isRequired,
  outcomes: PropTypes.instanceOf(Array).isRequired,
  americanFormat: PropTypes.bool.isRequired,
};

export default MarketView;
