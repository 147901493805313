import PropTypes from 'prop-types';
import classnames from 'classnames';
import { Modal } from './modal';

const directionClassName = (direction) => classnames('sort-filter__order', {
  descending: direction === 'DESC',
});

const SortingSelectorView = ({
  title,
  toggleSortingDirection,
  direction,
  isModalDisplayed,
  displayModal,
  hideModal,
}) => (
  <>
    <div className="sort-filter">
      <span className="sort-filter__title" onClick={displayModal}>
        { title }
      </span>
      <span
        className={directionClassName(direction)}
        onClick={toggleSortingDirection}
      />
    </div>

    { isModalDisplayed && <Modal onCloseClick={hideModal} /> }
  </>
);

SortingSelectorView.propTypes = {
  title: PropTypes.string.isRequired,
  toggleSortingDirection: PropTypes.func.isRequired,
  direction: PropTypes.string.isRequired,
  isModalDisplayed: PropTypes.bool.isRequired,
  displayModal: PropTypes.func.isRequired,
  hideModal: PropTypes.func.isRequired,
};

export default SortingSelectorView;
