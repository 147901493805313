import { useMemo, useContext } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import selectors from 'SportsbookOverview/redux/selectors';
import { ConfigContext } from 'SportsbookOverview/contexts';
import { makeGetMarketVisibleOutcomes } from 'sportsbook-markets-selector';
import { decimalPrice } from 'bv';
import MarketContainer from './market_container';

const nullMarket = {
  id: 0,
  mtId: undefined,
  o: [],
  st: -1,
};

const MarketRedux = ({ eventId }) => {
  const getMarket = useMemo(selectors.makeGetEventMarket, []);
  const getEvent = useMemo(selectors.makeGetEvent, []);
  const getMarketOutcomes = useMemo(makeGetMarketVisibleOutcomes, []);

  const { mtId, st, id } = useSelector((state) => getMarket(state, { eventId }))
    || nullMarket;

  const { inPlay } = useSelector((state) => getEvent(state, { eventId }));
  const outcomes = useSelector((state) => getMarketOutcomes(state, { id }));
  const { betBuilder2 } = useContext(ConfigContext);

  return (
    <MarketContainer
      eventId={eventId}
      inPlay={inPlay}
      outcomes={outcomes.map((o) => ({ ...o, prd: decimalPrice(o.pr) }))}
      disabled={st !== 1}
      mtId={mtId}
      betBuilder2={betBuilder2}
    />
  );
};

MarketRedux.propTypes = {
  eventId: PropTypes.number.isRequired,
};

export default MarketRedux;
