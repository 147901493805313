import { useEffect } from 'react';
import PropTypes from 'prop-types';
import AppView from './app_view';

const AppContainer = ({ fetchSuspensionReasons, hasEvents }) => {
  useEffect(fetchSuspensionReasons, []);

  return <AppView hasEvents={hasEvents} />;
};

AppContainer.propTypes = {
  fetchSuspensionReasons: PropTypes.func.isRequired,
  hasEvents: PropTypes.bool.isRequired,
};

export default AppContainer;
