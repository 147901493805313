import { useState, useEffect, useContext } from 'react';
import PropTypes from 'prop-types';
import { ConfigContext } from 'SportsbookOverview/contexts';
import { linkTypeServices } from 'SportsbookOverview/services';
import CardView from './card_view';

const CardContainer = ({
  cardId,
  cardTitle,
  hasMarkets,
  expanded,
  toggleExpanded,
  fetchMarkets,
  fetchEssentialScores,
  inPlayEventIds,
}) => {
  const [scoresFetched, setScoresFetched] = useState(false);
  const { linkType, ...config } = useContext(ConfigContext);

  // The cardBodyClassNames will be extracted from the coupon configured
  const cardBodyClassNames = linkTypeServices[linkType.value].cardBodyClassNames(config);
  // -------------------------------------------------------

  useEffect(() => {
    if (expanded && !scoresFetched) {
      // Fetch the essential scores for the card in play event ids (if any)
      if (inPlayEventIds.length > 0) {
        fetchEssentialScores({
          eventIds: inPlayEventIds,
        });
      }
      // ------------------------------------------

      setScoresFetched(true);
    }
  }, [expanded]);

  useEffect(() => {
    if (expanded) {
      fetchMarkets();
      // ------------------------------------------
    }
  }, [expanded]);

  return (
    <CardView
      cardId={cardId}
      cardTitle={cardTitle}
      cardBodyClassNames={cardBodyClassNames}
      hasMarkets={hasMarkets}
      expanded={expanded}
      toggleExpanded={toggleExpanded}
    />
  );
};

CardContainer.propTypes = {
  cardId: PropTypes.string.isRequired,
  cardTitle: PropTypes.string.isRequired,
  inPlayEventIds: PropTypes.instanceOf(Array).isRequired,
  hasMarkets: PropTypes.bool.isRequired,
  expanded: PropTypes.bool.isRequired,
  toggleExpanded: PropTypes.func.isRequired,
  fetchMarkets: PropTypes.func.isRequired,
  fetchEssentialScores: PropTypes.func.isRequired,
};

export default CardContainer;
