import PropTypes from 'prop-types';
import { withScrollToTop } from 'bv-hocs';
import { SortingSelector } from '../sorting_selector';
import { Cards } from '../cards';
import { NoEvents } from '../no_events';

const AppView = ({ hasEvents }) => (
  <div className="sportbook-wrapper">
    {hasEvents ? (
      <>
        <SortingSelector />
        <Cards />
      </>
    ) : <NoEvents />}
  </div>
);

AppView.propTypes = {
  hasEvents: PropTypes.bool.isRequired,
};

export default withScrollToTop()(AppView);
