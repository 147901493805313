import { useDispatch, useSelector } from 'react-redux';
import { compose, isEmpty } from 'underscore';
import { Redux as SuspensionReasonsRedux } from 'sportsbook-suspension-reasons';
import selectors from 'SportsbookOverview/redux/selectors';
import AppContainer from './app_container';

const AppRedux = () => {
  const dispatch = useDispatch();
  const fetchSuspensionReasons = compose(
    dispatch,
    SuspensionReasonsRedux.actionCreators.fetchSuspensionReasons,
  );
  const events = useSelector(selectors.getEvents);

  return (
    <AppContainer
      fetchSuspensionReasons={fetchSuspensionReasons}
      hasEvents={!isEmpty(events)}
    />
  );
};

export default AppRedux;
