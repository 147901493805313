import PropTypes from 'prop-types';
import { EnhancedPrice } from 'sportsbook-components';

const OutcomeDesc = ({ outcomeDesc }) => (
  <span className="short-description">{ outcomeDesc }</span>
);

OutcomeDesc.propTypes = {
  outcomeDesc: PropTypes.string.isRequired,
};

const OutcomeView = ({
  outcomeId,
  outcomeDesc,
  outcomeClassName,
  onClick,
  price,
  fractionalEnhancedOdds,
}) => (
  <span
    data-id={outcomeId}
    className={outcomeClassName}
    onClick={onClick}
  >
    {
      outcomeDesc && (
        <OutcomeDesc outcomeDesc={outcomeDesc} />
      )
    }
    {
      fractionalEnhancedOdds
        ? (
          <EnhancedPrice
            enhancedPrice={fractionalEnhancedOdds}
            oldPrice={price}
          />
        )
        : <strong>{ price }</strong>
    }
  </span>
);

OutcomeView.propTypes = {
  outcomeId: PropTypes.number.isRequired,
  outcomeDesc: PropTypes.string.isRequired,
  outcomeClassName: PropTypes.string.isRequired,
  price: PropTypes.string.isRequired,
  fractionalEnhancedOdds: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
};

export default OutcomeView;
