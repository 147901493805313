import { useRef } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { t } from 'bv-i18n';
import { useLockBodyScroll } from 'bv-hooks';
import { Modal, RenderInBody } from 'bv-components';

const listItemClassName = (method, activeSortingMethod) => classnames(
  'list-modal-item', {
    active: method.id === activeSortingMethod,
  },
);

const ModalView = ({
  onCloseClick,
  setSorting,
  activeSortingMethod,
  sortingMethods,
}) => {
  const scrollableRef = useRef();

  useLockBodyScroll(scrollableRef);

  return (
    <RenderInBody>
      <Modal
        info
        iconDotsClosable
        icon={false}
        className="list-modal"
        onCloseClick={onCloseClick}
      >
        <div ref={scrollableRef}>
          <div className="list-modal-top-title-wrapper" />
          <ul className="list-modal-items">
            {
            sortingMethods.map((method) => (
              <li
                className={listItemClassName(method, activeSortingMethod)}
                onClick={() => setSorting({ method: method.id })}
              >
                {t(method.title)}
              </li>
            ))
          }
          </ul>
        </div>
      </Modal>
    </RenderInBody>
  );
};

ModalView.propTypes = {
  onCloseClick: PropTypes.func.isRequired,
  setSorting: PropTypes.func.isRequired,
  activeSortingMethod: PropTypes.string.isRequired,
  sortingMethods: PropTypes.instanceOf(Array).isRequired,
};

export default ModalView;
