import { useMemo } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { isEqual } from 'underscore';
import selectors from 'SportsbookOverview/redux/selectors';
import MarketContainer from './market_container';

const MarketRedux = ({ eventId, marketId }) => {
  // Stablish redux selector to get the filtered and sorted outcome ids for the market
  const getFilteredMarketOutcomeIds = useMemo(
    selectors.makeGetOutrightMarketOutcomeIds,
    [],
  );
  const filteredOutcomeIds = useSelector(
    (state) => getFilteredMarketOutcomeIds(state, { marketId }), isEqual,
  );
  // --------------------------------------------

  // Stablish redux selector to extract the event for the given event id
  const getEvent = useMemo(selectors.makeGetEvent, []);
  const event = useSelector((state) => getEvent(state, { eventId }), isEqual);
  // --------------------------------------------

  return (
    <MarketContainer
      eventId={eventId}
      marketId={marketId}
      sportEventPathId={event.sportEventPathId}
      meetingId={event.meetingId}
      outcomeIds={filteredOutcomeIds}
    />
  );
};

MarketRedux.propTypes = {
  eventId: PropTypes.number.isRequired,
  marketId: PropTypes.number,
};

MarketRedux.defaultProps = {
  marketId: null,
};

export default MarketRedux;
