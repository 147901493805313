import { useContext } from 'react';
import { isEqual } from 'underscore';
import { useSelector, useDispatch } from 'react-redux';
import { ConfigContext } from 'SportsbookOverview/contexts';
import { linkTypeServices } from 'SportsbookOverview/services';
import Redux from 'SportsbookOverview/redux';
import CardsContainer from './cards_container';

const CardsRedux = () => {
  const { linkType, ...config } = useContext(ConfigContext);
  const dispatch = useDispatch();
  const marketOptions = linkTypeServices[linkType.value].marketOptions(config);

  // Stablish redux fetchMarkets method
  const fetchMarkets = () => dispatch(Redux.thunks.fetchMarkets(marketOptions));
  // --------------------------------------------

  const cards = useSelector(Redux.selectors.getCards, isEqual);

  return (
    <CardsContainer
      cards={cards}
      fetchMarkets={fetchMarkets}
      {...marketOptions}
    />
  );
};

export default CardsRedux;
