import { useMemo } from 'react';
import PropTypes from 'prop-types';
import { isEqual } from 'underscore';
import { useSelector } from 'react-redux';
import { Redux as SuspensionReasonsRedux } from 'sportsbook-suspension-reasons';
import { Redux as EssentialScoresRedux } from 'sportsbook-essential-scores';
import selectors from 'SportsbookOverview/redux/selectors';
import SuspendedContainer from './suspended_container';

const SuspendedRedux = ({ eventId }) => {
  // Stablish redux selector to extract the event for the given event id
  const getEvent = useMemo(selectors.makeGetEvent, []);
  const event = useSelector((state) => getEvent(state, { eventId }), isEqual);
  // --------------------------------------------

  const { sportId } = event;

  // Stablish redux selector to extract the suspension reasons for the
  // current sportId
  const { selectors: { makeGetSportSuspensionReasons } } = SuspensionReasonsRedux;
  const getSportSuspensionReasons = useMemo(makeGetSportSuspensionReasons, []);
  const suspensionReasons = useSelector(
    (state) => getSportSuspensionReasons(state, { sportId }),
    isEqual,
  );
  // --------------------------------------------

  // Stablish redux selector to extract the scoreboard info for the given event id
  const { selectors: { makeGetEventNoClockScoreboard } } = EssentialScoresRedux;
  const getEventNoClockScoreboard = useMemo(makeGetEventNoClockScoreboard, []);
  const scoreboard = useSelector((state) => getEventNoClockScoreboard(state, { eventId }), isEqual);
  // --------------------------------------------

  return (
    <SuspendedContainer
      suspensionReasons={suspensionReasons}
      scoreboard={scoreboard}
    />
  );
};

SuspendedRedux.propTypes = {
  eventId: PropTypes.number.isRequired,
};

export default SuspendedRedux;
