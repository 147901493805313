import { initialSorting, sorting } from 'SportsbookOverview/services';
import actionCreators from './action_creators';
import { SET_GROUPS_BY_CARD } from './action_types';

export default (store) => (next) => (action) => {
  next(action);

  switch (action.type) {
    case SET_GROUPS_BY_CARD: {
      const state = store.getState();
      const sortingMethods = sorting(state);

      store.dispatch(actionCreators.setSorting(initialSorting(sortingMethods)));

      break;
    }

    default:
      break;
  }
};
