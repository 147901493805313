import { useEffect } from 'react';
import { toArray } from 'underscore';
import PropTypes from 'prop-types';
import CardsView from './cards_view';

const CardsContainer = ({ cards, fetchMarkets, ...marketOptions }) => {
  useEffect(fetchMarkets, toArray(marketOptions));

  return (
    <CardsView
      cards={cards}
      fetchMarkets={fetchMarkets}
    />
  );
};

CardsContainer.propTypes = {
  cards: PropTypes.instanceOf(Array).isRequired,
  fetchMarkets: PropTypes.func.isRequired,
  marketTypeDimension: PropTypes.string.isRequired,
  periodDimension: PropTypes.string.isRequired,
};

export default CardsContainer;
