import Overview from './overview';
import BetBoost from './bet_boost';
import EnhancedAccas from './enhanced_accas';
import BetBuilder from './bet_builder';
import PiuEvents from './piu_events';

export default {
  overview: Overview,
  bet_boost: BetBoost,
  enhanced_accas: EnhancedAccas,
  bet_builder: BetBuilder,
  piu_events: PiuEvents,
};
