/* eslint no-undef: off */
import PropTypes from 'prop-types';
import ScoreHeadersView from './score_headers_view';

const labelsInfo = (sportId, couponTypeValue) => {
  // Darts is a special case that doesnt depend on coupon type
  if (sportId === 965300) {
    return {
      additionalClassName: 'inplay-coupon-header-set-scores',
      labels: [
        BvApi.t('darts.periods.sets_abrv'),
        BvApi.t('darts.periods.legs_abrv'),
        BvApi.t('darts.periods.points_abrv'),
      ],
    };
  }

  if (couponTypeValue === 'template_d') {
    return {
      additionalClassName: 'inplay-coupon-header-set-scores',
      labels: [
        BvApi.t('tennis.periods.sets_abrv'),
        BvApi.t('tennis.periods.games_abrv'),
        BvApi.t('tennis.periods.points_abrv'),
      ],
    };
  }

  return {
    additionalClassName: '',
    labels: [],
  };
};

const ScoreHeadersContainer = ({ areInPlay, sportId, couponType }) => {
  const { additionalClassName, labels } = labelsInfo(sportId, couponType);

  return (
    <ScoreHeadersView
      areInPlay={areInPlay}
      additionalClassName={additionalClassName}
      labels={labels}
    />
  );
};

ScoreHeadersContainer.propTypes = {
  areInPlay: PropTypes.bool.isRequired,
  sportId: PropTypes.number.isRequired,
  couponType: PropTypes.string.isRequired,
};

export default ScoreHeadersContainer;
