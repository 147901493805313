import { useMemo } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { isEqual } from 'underscore';
import { makeGetMarket } from 'sportsbook-markets-selector';
import selectors from 'SportsbookOverview/redux/selectors';
import OutcomeContainer from './outcome_container';

const OutcomeRedux = (props) => {
  // Stablish redux selector to extract the event for the given event id
  const getEvent = useMemo(selectors.makeGetEvent, []);
  const event = useSelector((state) => getEvent(state, props), isEqual);
  // --------------------------------------------

  // Stablish redux selector to extract the market for the given market id
  const getMarket = useMemo(makeGetMarket, []);
  const market = useSelector((state) => getMarket(state, props), isEqual);
  // --------------------------------------------

  return (
    <OutcomeContainer
      {...props}
      event={event}
      market={market}
      clickSource="in-play"
    />
  );
};

OutcomeRedux.propTypes = {
  eventId: PropTypes.number.isRequired,
};

export default OutcomeRedux;
