import { useContext } from 'react';
import PropTypes from 'prop-types';
import { eventHref } from 'SportsbookOverview/services';
import { ConfigContext } from 'SportsbookOverview/contexts';
import SeeMoreMarketsView from './see_more_markets_view';

const SeeMoreMarketsContainer = ({ eventId, sportEventPathId, meetingId }) => {
  const { couponHeaders } = useContext(ConfigContext);
  return (
    <SeeMoreMarketsView
      couponHeaders={couponHeaders}
      eventHref={eventHref(sportEventPathId, meetingId, eventId)}
    />
  );
};

SeeMoreMarketsContainer.propTypes = {
  eventId: PropTypes.number.isRequired,
  sportEventPathId: PropTypes.number,
  meetingId: PropTypes.number.isRequired,
};

SeeMoreMarketsContainer.defaultProps = {
  sportEventPathId: 240,
};

export default SeeMoreMarketsContainer;
