import { useMemo } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import selectors from 'SportsbookOverview/redux/selectors';
import EventTitleContainer from './event_title_container';

const EventTitleRedux = ({ eventId }) => {
  const getEvent = useMemo(selectors.makeGetEvent, []);
  const { description, startTime, inPlay } = useSelector((state) => getEvent(state, { eventId }));

  return (
    <EventTitleContainer
      description={description}
      startTime={startTime}
      inPlay={inPlay}
    />
  );
};

EventTitleRedux.propTypes = {
  eventId: PropTypes.number.isRequired,
};

export default EventTitleRedux;
