import PropTypes from 'prop-types';
import EventTitle from 'SportsbookOverview/components/link_types/shared/event_title';
import ShowMore from 'SportsbookOverview/components/link_types/shared/show_more';
import { t } from 'bv-i18n';
import Market from '../market';

const Event = ({ eventId }) => (
  <div data-event-id={eventId} className="inplay-coupon-row">
    <EventTitle eventId={eventId} />
    <Market eventId={eventId} />
    <ShowMore eventId={eventId} labelText={t('go_to_event')} />
  </div>
);

Event.propTypes = {
  eventId: PropTypes.number.isRequired,
};

export default Event;
