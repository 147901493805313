import { useSelector } from 'react-redux';
import Redux from 'SportsbookOverview/redux';
import { sorting } from 'SportsbookOverview/services';
import { useSetSorting } from './hooks/use_set_sorting';
import SortingSelectorContainer from './sorting_selector_container';

const SortingSelectorRedux = () => {
  // Stablish redux action dispatching to change sorting direction
  const setSorting = useSetSorting();
  // --------------------------------------------

  // ----- Extract list of sorting methods
  const sortingMethods = useSelector(sorting);
  // ----------------------------------------

  // Stablish redux selector to extract the sorting method
  const activeSorting = useSelector(Redux.selectors.getSorting);
  // --------------------------------------------

  return (
    <SortingSelectorContainer
      sortingMethods={sortingMethods}
      activeSorting={activeSorting}
      setSorting={setSorting}
    />
  );
};

export default SortingSelectorRedux;
