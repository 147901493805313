import PropTypes from 'prop-types';
import { Card } from '../card';

const CardsView = ({ cards, fetchMarkets }) => cards.map((card) => (
  <Card
    key={card.id}
    cardId={card.id}
    cardTitle={card.title}
    hasMarkets={card.hasMarkets}
    expanded={card.expanded}
    fetchMarkets={fetchMarkets}
  />
));

CardsView.propTypes = {
  cards: PropTypes.instanceOf(Array).isRequired,
  fetchMarkets: PropTypes.func.isRequired,
};

export default CardsView;
