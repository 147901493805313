import { useMemo } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { isEqual } from 'underscore';
import selectors from 'SportsbookOverview/redux/selectors';
import SeeMoreMarketsContainer from './see_more_markets_container';

const SeeMoreMarketsRedux = ({ eventId }) => {
  // Stablish redux selector to extract the event for the given event id
  const getEvent = useMemo(selectors.makeGetEvent, []);
  const event = useSelector((state) => getEvent(state, { eventId }), isEqual);
  // --------------------------------------------

  return (
    <SeeMoreMarketsContainer
      eventId={eventId}
      sportEventPathId={event.sportEventPathId}
      meetingId={event.meetingId}
    />
  );
};

SeeMoreMarketsRedux.propTypes = {
  eventId: PropTypes.number.isRequired,
};

export default SeeMoreMarketsRedux;
