import { useMemo } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { isEqual } from 'underscore';
import { makeGetMarket } from 'sportsbook-markets-selector';
import { makeGetEvent } from 'sportsbook-events-selector';
import { makeGetOutcomesByIds } from 'sportsbook-outcomes-selector';
import selectors from 'SportsbookOverview/redux/selectors';
import MarketContainer from './market_container';

const MarketRedux = ({ eventId, marketId }) => {
  // Stablish redux selector to extract the market for the given event id
  const getMarket = useMemo(makeGetMarket, []);
  const getEvent = useMemo(makeGetEvent, []);
  const getOutcomes = useMemo(makeGetOutcomesByIds, []);
  const market = useSelector((state) => getMarket(state, { marketId }), isEqual);
  const event = useSelector((state) => getEvent(state, { eventId }), isEqual);
  // --------------------------------------------

  // Stablish redux selector to get the sorted outcome ids for the market
  const getDefaultMarketOutcomeIds = useMemo(
    selectors.makeGetDefaultMarketOutcomeIds,
    [],
  );
  const outcomeIds = useSelector(
    (state) => getDefaultMarketOutcomeIds(state, { marketId }), isEqual,
  );
  const outcomes = useSelector((state) => getOutcomes(state, { outcomes: outcomeIds }));
  // --------------------------------------------

  return (
    <MarketContainer
      eventId={eventId}
      americanFormat={event.americanFormat}
      outcomeIds={outcomeIds}
      market={market}
      outcomes={outcomes}
    />
  );
};

MarketRedux.propTypes = {
  eventId: PropTypes.number.isRequired,
  marketId: PropTypes.number,
};

MarketRedux.defaultProps = {
  marketId: null,
};

export default MarketRedux;
