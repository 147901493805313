import PropTypes from 'prop-types';

const EventTitleView = ({ description, startTime }) => (
  <h4 className="inplay-coupon-event-title">{`${description} - ${startTime}`}</h4>
);

EventTitleView.propTypes = {
  description: PropTypes.string.isRequired,
  startTime: PropTypes.string.isRequired,
};

export default EventTitleView;
