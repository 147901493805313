import { useState, useContext } from 'react';
import PropTypes from 'prop-types';
import { t } from 'bv-i18n';
import { ConfigContext } from 'SportsbookOverview/contexts';
import SortingSelectorView from './sorting_selector_view';

const SortingSelectorContainer = ({ sortingMethods, activeSorting, setSorting }) => {
  const [isModalDisplayed, setDisplayModal] = useState(false);
  const displayModal = () => setDisplayModal(true);
  const hideModal = () => setDisplayModal(false);
  const { sorting } = useContext(ConfigContext);

  if (!sorting) return null;

  const activeSortingMethodKey = sortingMethods
    .find((s) => s.id === activeSorting.method)
    .title;

  const toggleSortingDirection = () => (activeSorting.direction === 'ASC'
    ? setSorting({ direction: 'DESC' })
    : setSorting({ direction: 'ASC' })
  );

  // TODO: Review translations
  return (
    <SortingSelectorView
      title={t(`${activeSortingMethodKey}_sorted_by`)}
      toggleSortingDirection={toggleSortingDirection}
      direction={activeSorting.direction}
      isModalDisplayed={isModalDisplayed}
      displayModal={displayModal}
      hideModal={hideModal}
    />
  );
};

SortingSelectorContainer.propTypes = {
  sortingMethods: PropTypes.instanceOf(Array).isRequired,
  activeSorting: PropTypes.instanceOf(Object).isRequired,
  setSorting: PropTypes.func.isRequired,
};

export default SortingSelectorContainer;
