import PropTypes from 'prop-types';
import { t, locale } from 'bv-i18n';
import EventTitleView from './event_title_view';

const getStartTimeString = (startTime, inPlay) => {
  if (inPlay) return t('javascript.in_play.live');

  return new Date(startTime).toLocaleString(locale(), {
    hour: '2-digit',
    minute: '2-digit',
    hourCycle: 'h23',
  });
};

const EventTitleContainer = ({ description, startTime, inPlay }) => (
  <EventTitleView
    description={description}
    startTime={getStartTimeString(startTime, inPlay)}
  />
);

EventTitleContainer.propTypes = {
  description: PropTypes.string.isRequired,
  startTime: PropTypes.string.isRequired,
  inPlay: PropTypes.bool.isRequired,
};

export default EventTitleContainer;
