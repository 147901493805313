import PropTypes from 'prop-types';
import EventTitle from 'SportsbookOverview/components/link_types/shared/event_title';
import ShowMore from 'SportsbookOverview/components/link_types/shared/show_more';
import OutcomesHeader from 'SportsbookCommon/bet_boost/outcomes_header';

const Event = ({ eventId, outcomesComponent, showMore }) => {
  const Outcomes = outcomesComponent;
  // if component has a way to indicate number of outcomes it will display
  // assume it shouln't be rendered if no outcomes found.
  const hasOutcomes = Outcomes?.outcomesSelector
    ? Outcomes.outcomesSelector(eventId).length > 0
    : true;

  if (!hasOutcomes) return null;

  return (
    <>
      <EventTitle eventId={eventId} />
      <div className="market-view-body-wrapper">
        <OutcomesHeader eventId={eventId} />
        <div className="market-view-body">
          <Outcomes eventId={eventId} />
          {
            showMore && (
              <ShowMore eventId={eventId} />
            )
          }
        </div>
      </div>
    </>
  );
};

Event.propTypes = {
  eventId: PropTypes.number.isRequired,
  outcomesComponent: PropTypes.node.isRequired,
  showMore: PropTypes.bool,
};

Event.defaultProps = {
  showMore: true,
};

export default Event;
