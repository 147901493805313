import { useMemo } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { t } from 'bv-i18n';
import selectors from 'SportsbookOverview/redux/selectors';
import ShowMoreContainer from './show_more_container';

const ShowMoreRedux = ({ eventId, labelText }) => {
  const getEvent = useMemo(selectors.makeGetEvent, []);
  const { sportEventPathId, meetingId } = useSelector((state) => getEvent(state, { eventId }));

  return (
    <ShowMoreContainer
      eventId={eventId}
      sportEventPathId={sportEventPathId}
      meetingId={meetingId}
      labelText={labelText}
    />
  );
};

ShowMoreRedux.propTypes = {
  eventId: PropTypes.number.isRequired,
  labelText: PropTypes.string,
};

ShowMoreRedux.defaultProps = {
  labelText: t('javascript.show_more'),
};

export default ShowMoreRedux;
