import actionCreators from './action_creators';
import thunks from './thunks';
import reducer from './reducer';
import selectors from './selectors';
import middleware from './middleware';

export default {
  actionCreators,
  reducer,
  selectors,
  thunks,
  middleware,
};
