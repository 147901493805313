import { useMemo } from 'react';
import PropTypes from 'prop-types';
import { isEqual } from 'underscore';
import Redux from 'SportsbookOverview/redux';
import { useDispatch, useSelector } from 'react-redux';

import CardContainer from './card_container';

const CardRedux = ({
  cardId, cardTitle, hasMarkets, expanded, fetchMarkets,
}) => {
  const dispatch = useDispatch();

  // Stablish redux fetchEssentialScores method
  const fetchEssentialScores = (opts) => dispatch(Redux.thunks.fetchEssentialScores(opts));
  // --------------------------------------------

  // Stablish redux toggleCardExpanded method
  const toggleExpanded = (opts) => dispatch(Redux.actionCreators.toggleCardExpanded(opts));
  // --------------------------------------------

  // Stablish redux selector to extract in play event ids for the given cardId
  const getCardInPlayEventIds = useMemo(Redux.selectors.makeGetCardInPlayEventIds, []);
  const inPlayEventIds = useSelector((state) => getCardInPlayEventIds(state, { cardId }), isEqual);
  // --------------------------------------------

  return (
    <CardContainer
      cardId={cardId}
      cardTitle={cardTitle}
      hasMarkets={hasMarkets}
      expanded={expanded}
      toggleExpanded={toggleExpanded}
      fetchMarkets={fetchMarkets}
      fetchEssentialScores={fetchEssentialScores}
      inPlayEventIds={inPlayEventIds}
    />
  );
};

CardRedux.propTypes = {
  cardId: PropTypes.string.isRequired,
  cardTitle: PropTypes.string.isRequired,
  hasMarkets: PropTypes.bool.isRequired,
  expanded: PropTypes.bool.isRequired,
  fetchMarkets: PropTypes.func.isRequired,
};

export default CardRedux;
