import { useContext } from 'react';
import PropTypes from 'prop-types';
import { formatPrice, isMarketOpen, isMarketSuspended } from 'sportsbook-helpers';
import { usePriceFormatId } from 'bv-hooks';
import withOutcome from 'sportsbook-with-outcome';
import { ConfigContext } from 'SportsbookOverview/contexts';
import { eventHref } from 'SportsbookOverview/services';
import OutcomeView from './outcome_view';

const OutcomeContainer = ({
  eventId,
  event,
  market,
  outcome,
  selected,
  toggleBetslip,
}) => {
  const { id, desc, pr } = outcome;
  const { meetingId, sportEventPathId } = event;
  const { couponHeaders } = useContext(ConfigContext);
  const priceFormatId = usePriceFormatId();

  return (
    <OutcomeView
      outcomeId={id}
      outcomeClassName={`bvs-button-multi-sport ${selected ? 'active' : ''}`}
      outcomeDesc={desc}
      price={formatPrice(pr, priceFormatId)}
      onClick={toggleBetslip}
      marketEnabled={isMarketOpen(market)}
      marketSuspended={isMarketSuspended(market)}
      eventHref={eventHref(sportEventPathId || 240, meetingId, eventId)}
      couponHeaders={couponHeaders}
    />
  );
};

OutcomeContainer.propTypes = {
  eventId: PropTypes.number.isRequired,
  outcome: PropTypes.instanceOf(Object).isRequired,
  market: PropTypes.instanceOf(Object).isRequired,
  event: PropTypes.instanceOf(Object).isRequired,
  selected: PropTypes.bool.isRequired,
  toggleBetslip: PropTypes.func.isRequired,
};

export default withOutcome(OutcomeContainer, { idAttribute: 'outcomeId' });
