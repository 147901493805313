import PropTypes from 'prop-types';
import { PriceItUpComponent } from 'sportsbook-markets-views';

// This is the Format the PriceItUpComponent expect for its props
const piuCouponData = ({
  eventId, inPlay, mtId, outcomes, disabled, betBuilder2,
}) => ({
  market: {
    eId: eventId,
    mtId,
    disabled,
  },
  outcomes,
  options: {
    priceBands: [
      ['<', '5/1'], ['5/1', '10/1'], ['10/1', '33/1'], ['33/1', '>'],
    ],
    defaultTab: 1,
    outcomesToShow: outcomes.length,
    eventStage: inPlay ? 'live' : 'pre_event',
    betBuilder2,
  },
});

const MarketContainer = ({
  eventId,
  inPlay,
  mtId,
  outcomes,
  disabled,
  betBuilder2,
}) => (
  <PriceItUpComponent
    {...piuCouponData({
      eventId, inPlay, mtId, outcomes, disabled, betBuilder2,
    })}
  />
);

MarketContainer.propTypes = {
  eventId: PropTypes.number.isRequired,
  inPlay: PropTypes.bool.isRequired,
  mtId: PropTypes.number.isRequired,
  outcomes: PropTypes.instanceOf(Array).isRequired,
  disabled: PropTypes.bool.isRequired,
  betBuilder2: PropTypes.bool.isRequired,
};

export default MarketContainer;
