import { useMemo } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import selectors from 'SportsbookOverview/redux/selectors';
import OutcomesView from './outcomes_view';

const OutcomesRedux = ({ eventId }) => {
  const getMarkets = useMemo(selectors.makeGetEventMarkets, []);
  const markets = useSelector((state) => getMarkets(state, { eventId }));

  const outcomes = useMemo(() => markets.flatMap((market) => market.o.map((id) => ({
    id,
    marketStatus: market.st,
  }))), [markets]);

  return (
    <OutcomesView outcomes={outcomes} />
  );
};

OutcomesRedux.propTypes = {
  eventId: PropTypes.number.isRequired,
};

export default OutcomesRedux;
