import { useContext, useMemo } from 'react';
import PropTypes from 'prop-types';
import { ConfigContext, TemplateContext } from 'SportsbookOverview/contexts';

import { Overview } from 'sportsbook-common';
import { TemplateDefault, TemplateC } from './templates';
import { DefaultMarket, OutrightMarket } from './market';
import { DefaultOutcome, TemplateCOutcome, TemplateDOutcome } from './outcome';

const NullComponent = () => null;

const sportEventDetailComponent = (sportId, ignoreOutcomeKeys) => {
  // Darts sport which cannot be discerned atm in the CMS as it can use the usual
  // coupon types but always needs to display the scoreboard on its specific
  // format, this is a hack that we're carrying from inPlay overview
  if (sportId === 965300) {
    return Overview.EventDetail.Sets;
  }

  if (ignoreOutcomeKeys) {
    return Overview.EventDetail.MarketDescription;
  }

  return Overview.EventDetail.Default;
};

const defaultTemplateMapping = (sportId, ignoreOutcomeKeys) => ({
  templateComponent: TemplateDefault,
  eventCommentComponent: Overview.EventComment,
  eventTimeComponent: Overview.ClockInfo.DefaultClockInfo,
  eventDetailComponent: sportEventDetailComponent(sportId, ignoreOutcomeKeys),
  marketComponent: DefaultMarket,
  outcomeComponent: DefaultOutcome,
});

const templateMapping = (sportId, ignoreOutcomeKeys) => ({
  template_a: {
    ...defaultTemplateMapping(sportId, ignoreOutcomeKeys),
  },
  template_b: {
    ...defaultTemplateMapping(sportId, ignoreOutcomeKeys),
  },
  template_b1: {
    ...defaultTemplateMapping(sportId, ignoreOutcomeKeys),
  },
  template_c: {
    templateComponent: TemplateC,
    eventTimeComponent: Overview.ClockInfo.OutrightClockInfo,
    eventCommentComponent: NullComponent,
    eventDetailComponent: Overview.EventDetail.Outright,
    marketComponent: OutrightMarket,
    outcomeComponent: TemplateCOutcome,

  },
  template_d: {
    ...defaultTemplateMapping(sportId, false),
    eventDetailComponent: Overview.EventDetail.Sets,
    outcomeComponent: TemplateDOutcome,
  },
});

const getTemplateComponents = (sportId, couponType, ignoreOutcomeKeys) => templateMapping(
  sportId, ignoreOutcomeKeys)[couponType];

const EventContainer = ({
  eventId, marketsIds, startTime, sportId,
}) => {
  const { couponType, ignoreOutcomeKeys, showAllEventMarkets } = useContext(ConfigContext);

  const {
    templateComponent: Template,
    outcomeComponent,
    ...templateComponents
  } = getTemplateComponents(sportId, couponType.value, ignoreOutcomeKeys);

  const contextValue = useMemo(() => ({ outcomeComponent }), [outcomeComponent]);

  const filteredMarketsIds = showAllEventMarkets && marketsIds.length > 1
    ? marketsIds : [marketsIds[0]];

  return (
    // We can use the TemplateContext to set up components per template
    // that will be used deep in the hierarchy
    <TemplateContext.Provider value={contextValue}>
      {filteredMarketsIds.map((marketId) => (
        <Template
          key={marketId}
          eventId={eventId}
          marketId={marketId}
          startTime={startTime}
          {...templateComponents}
        />
      ))}
    </TemplateContext.Provider>
  );
};

EventContainer.propTypes = {
  eventId: PropTypes.number.isRequired,
  marketsIds: PropTypes.arrayOf(PropTypes.number).isRequired,
  startTime: PropTypes.string.isRequired,
  sportId: PropTypes.number.isRequired,
};

export default EventContainer;
