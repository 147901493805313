import { addMiddleware } from 'redux-dynamic-middlewares';
import Redux from './redux';
import * as OverviewContexts from './contexts';

const { addReducers } = window.reduxState;

addReducers({
  'sportsbook/overview': Redux.reducer,
});

addMiddleware(
  Redux.middleware,
);

export { default as Components } from './components';
export { default as Redux } from './redux';
export const Contexts = OverviewContexts;
