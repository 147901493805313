import PropTypes from 'prop-types';
import classnames from 'classnames';
import { Icon } from 'bv-components';
import { EventsGroups } from '../events_groups';

const Card = ({
  cardId,
  cardTitle,
  cardBodyClassNames,
  hasMarkets,
  expanded,
  toggleExpanded,
}) => (
  <div className={classnames('expandable', 'market-card', { close: !expanded, hidden: expanded && !hasMarkets })}>
    <div className="bvs-card-header bvs-link" onClick={() => toggleExpanded({ cardId })}>
      <h2 className="coupon-header-title">{ cardTitle }</h2>
      <div className="coupon-header-icons">
        <Icon
          id={`arrow-solid-${expanded ? 'circle-up' : 'circle-down'}`}
        />
      </div>
    </div>
    {
      expanded && (
        <div className={`bvs-card inplay-coupon ${cardBodyClassNames}`}>
          <EventsGroups cardId={cardId} cardHasMarkets={hasMarkets} />
        </div>
      )
    }
  </div>
);

Card.propTypes = {
  cardId: PropTypes.string.isRequired,
  cardTitle: PropTypes.string.isRequired,
  cardBodyClassNames: PropTypes.string.isRequired,
  hasMarkets: PropTypes.bool,
  expanded: PropTypes.bool,
  toggleExpanded: PropTypes.func,
};

Card.defaultProps = {
  hasMarkets: true,
  expanded: true,
  toggleExpanded: () => {},
};

export default Card;
