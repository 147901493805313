import PropTypes from 'prop-types';
import { Button } from 'bv-components';

const ShowMoreView = ({ eventHref, labelText }) => (
  <div className="market-view__see-more">
    <Button
      href={eventHref}
      primary
    >
      { labelText }
    </Button>
  </div>
);

ShowMoreView.propTypes = {
  eventHref: PropTypes.string.isRequired,
  labelText: PropTypes.string.isRequired,
};

export default ShowMoreView;
