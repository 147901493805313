/* eslint no-undef: off */
import PropTypes from 'prop-types';
import { linkLocalizer } from 'bv-helpers/locale';

const SeeMoreMarketsView = ({ eventHref, couponHeaders }) => (
  <div className={`inplay-coupon-odds-column inplay-headers-count-${couponHeaders?.length}`}>
    <a href={linkLocalizer(eventHref)} className="bvs-button-multi-sport more-markets">
      { /* TODO: Replace BvApi.t for the new BVI18n */ }
      { BvApi.t('in_play.see_more_markets') }
      { /* ---------------------------------------- */ }
    </a>
  </div>
);

SeeMoreMarketsView.propTypes = {
  eventHref: PropTypes.string.isRequired,
  couponHeaders: PropTypes.instanceOf(Array).isRequired,
};

export default SeeMoreMarketsView;
