import { createSelector } from 'reselect';

const getEventsMarkets = (state) => state['sportsbook/overview'].eventsActiveMarket || {};
const getPropsEventIds = (_, props) => props.eventIds || [];

export default () => createSelector(
  getEventsMarkets,
  getPropsEventIds,
  (eventsMarkets, eventIds) => (
    eventIds.reduce((acc, eventId) => (acc.concat(...(eventsMarkets[eventId] || []))), [])
  ),
);
