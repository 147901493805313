/* eslint no-undef: off */
import PropTypes from 'prop-types';

const SuspendedView = ({ suspensionReasonDescription }) => (
  <span className="bvs-button-multi-sport suspended">
    { /* TODO: Replace BvApi.t for the new BVI18n */ }
    { BvApi.t('suspended') }
    {
      suspensionReasonDescription && (
        <span className="inplay-coupon-suspension-reason">
          { suspensionReasonDescription }
        </span>
      )
    }
  </span>
);

SuspendedView.propTypes = {
  suspensionReasonDescription: PropTypes.string,
};

SuspendedView.defaultProps = {
  suspensionReasonDescription: undefined,
};

export default SuspendedView;
