import { useMemo } from 'react';
import PropTypes from 'prop-types';
import { isEqual } from 'underscore';
import { useSelector } from 'react-redux';
import selectors from 'SportsbookOverview/redux/selectors';
import EventContainer from './event_container';

const EventRedux = ({ eventId }) => {
  // Stablish redux selector to extract the sport id for the given event id
  const getEvent = useMemo(selectors.makeGetEvent, []);
  const { sportId, startTime } = useSelector((state) => getEvent(state, { eventId }), isEqual);
  // --------------------------------------------

  const getMarketsIds = useMemo(selectors.makeGetEventMarketsIds, []);
  const marketsIds = useSelector((state) => getMarketsIds(state, { eventId }));

  return (
    <EventContainer
      eventId={eventId}
      marketsIds={marketsIds}
      startTime={startTime}
      sportId={sportId}
    />
  );
};

EventRedux.propTypes = {
  eventId: PropTypes.number.isRequired,
};

export default EventRedux;
