import PropTypes from 'prop-types';
import { Overview } from 'sportsbook-common';

const Event = ({ eventId, eventHref }) => (
  <div data-event-id={eventId} className="inplay-coupon-row">
    { /* TODO: Styling needs a review, actually all overview HTML needs to be reviewed */ }
    <Overview.ClockInfo.DefaultClockInfo eventId={eventId} />
    <Overview.EventDetail.Default eventId={eventId} showMore={false} eventHref={eventHref} />
    <span className="bvs-icon is-arrow-right" />
  </div>
);

Event.propTypes = {
  eventId: PropTypes.number.isRequired,
  eventHref: PropTypes.string.isRequired,
};

export default Event;
