import { useMemo, useContext } from 'react';
import PropTypes from 'prop-types';
import { isEqual } from 'underscore';
import { useSelector } from 'react-redux';
import { makeGetMarket } from 'sportsbook-markets-selector';
import { ConfigContext } from 'SportsbookOverview/contexts';
import SeeMoreMarketsView from './see_more_markets_view';

const SeeMoreMarketsRedux = ({ eventId, marketId }) => {
  // Stablish redux selector to extract the market for the given market id
  const getMarket = useMemo(makeGetMarket, []);
  const market = useSelector((state) => getMarket(state, { marketId }), isEqual);
  // --------------------------------------------
  const { couponHeaders } = useContext(ConfigContext);

  return (
    <SeeMoreMarketsView
      eventId={eventId}
      hasMarket={!!market}
      couponHeaders={couponHeaders}
    />
  );
};

SeeMoreMarketsRedux.propTypes = {
  eventId: PropTypes.number.isRequired,
  marketId: PropTypes.number,
};

SeeMoreMarketsRedux.defaultProps = {
  marketId: null,
};

export default SeeMoreMarketsRedux;
