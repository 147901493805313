/* eslint no-undef: off */
import PropTypes from 'prop-types';
import { linkLocalizer } from 'bv-helpers/locale';

const OutcomeView = ({
  outcomeId,
  outcomeClassName,
  outcomeDesc,
  price,
  onClick,
  marketEnabled,
  marketSuspended,
  eventHref,
}) => (
  <div className="inplay-coupon-row">
    <div className="inplay-coupon-competition-column">
      <a className="inplay-coupon-competition-team" href={linkLocalizer(eventHref)}>
        <span className="inplay-coupon-team-name">{outcomeDesc}</span>
      </a>
    </div>
    <div className="inplay-coupon-odds-column">
      {
        marketEnabled && (
          <span
            data-id={outcomeId}
            className={outcomeClassName}
            onClick={onClick}
          >
            {price}
          </span>
        )
      }
      {
        marketSuspended && (
          <span className="bvs-button-multi-sport suspended">
            { /* TODO: Move away from BvApi.t */ }
            {BvApi.t('suspended')}
          </span>
        )
      }
    </div>
  </div>
);

OutcomeView.propTypes = {
  outcomeId: PropTypes.number.isRequired,
  outcomeClassName: PropTypes.string.isRequired,
  outcomeDesc: PropTypes.string.isRequired,
  price: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
  marketEnabled: PropTypes.bool.isRequired,
  marketSuspended: PropTypes.bool.isRequired,
  eventHref: PropTypes.string.isRequired,
};

export default OutcomeView;
