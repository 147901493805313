import { t } from 'bv-i18n';
import ErrorItems from 'ErrorPage/components/error_items';

const NoEventsView = () => (
  <div className="no-events-message" data-track="no-markets-list">
    <div className="no-events-message__icon" />
    <div className="no-events-message__text">
      <p>{t('javascript.no_events')}</p>

      <div className="error-page-wrapper">
        <ErrorItems />
      </div>
    </div>
  </div>
);

export default NoEventsView;
