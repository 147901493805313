import PropTypes from 'prop-types';

const ScoreHeadersView = ({ areInPlay, labels, additionalClassName }) => (
  <div className="inplay-coupon-score-colum">
    {
      areInPlay && (
        <div className={`inplay-coupon-score-row ${additionalClassName}`}>
          {
            labels.map((label) => (
              <span className="inplay-coupon-score-label">{label}</span>
            ))
          }
        </div>
      )
    }
  </div>
);

ScoreHeadersView.propTypes = {
  areInPlay: PropTypes.bool.isRequired,
  labels: PropTypes.instanceOf(Array).isRequired,
  additionalClassName: PropTypes.string.isRequired,
};

export default ScoreHeadersView;
