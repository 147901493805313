import { useMemo, useContext } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { isEqual } from 'underscore';
import { ConfigContext } from 'SportsbookOverview/contexts';
import Redux from 'SportsbookOverview/redux';
import EventsGroupsView from './events_groups_view';

const EventsGroupsRedux = ({ cardId, cardHasMarkets }) => {
  const { sorting } = useContext(ConfigContext);

  // Stablish redux selector to extract the events groups for the given cardId
  const getCardEventsGroups = useMemo(Redux.selectors.makeGetCardEventsGroups, []);
  const eventsGroups = useSelector((state) => getCardEventsGroups(state, { cardId, sorting }),
    isEqual);
  // --------------------------------------------

  return (
    <EventsGroupsView
      cardId={cardId}
      cardHasMarkets={cardHasMarkets}
      eventsGroups={eventsGroups}
    />
  );
};

EventsGroupsRedux.propTypes = {
  cardId: PropTypes.string.isRequired,
  cardHasMarkets: PropTypes.bool.isRequired,
};

export default EventsGroupsRedux;
