import { compose } from 'underscore';
import PropTypes from 'prop-types';
import ModalView from './modal_view';

const ModalContainer = ({
  onCloseClick, setSorting, sortingMethods, activeSortingMethod,
}) => (
  <ModalView
    onCloseClick={onCloseClick}
    setSorting={compose(onCloseClick, setSorting)}
    activeSortingMethod={activeSortingMethod}
    sortingMethods={sortingMethods}
  />
);

ModalContainer.propTypes = {
  onCloseClick: PropTypes.func.isRequired,
  setSorting: PropTypes.func.isRequired,
  sortingMethods: PropTypes.instanceOf(Array).isRequired,
  activeSortingMethod: PropTypes.string.isRequired,
};

export default ModalContainer;
