import PropTypes from 'prop-types';
import { useContext, useMemo } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { makeGetEvent } from 'sportsbook-events-selector';
import { makeGetMarketOutcomes } from 'sportsbook-markets-selector';
import { shouldApplyAmericanFormat } from 'sportsbook-helpers';
import { ConfigContext } from 'SportsbookOverview/contexts';
import setCardHasMarkets from 'SportsbookOverview/redux/action_creators/set_card_has_markets';
import makeGetEventsMarketsIds from 'SportsbookOverview/redux/selectors/make_get_events_markets_ids';
import EventsGroupContainer from './events_group_container';

const getEventsMarketsIds = makeGetEventsMarketsIds();
const hideEmptyMarketCouponTypes = ['bet_boost', 'enhanced_accas'];

const EventsGroupRedux = ({ cardId, cardHasMarkets, eventsGroup }) => {
  const dispatch = useDispatch();
  const getEvent = useMemo(makeGetEvent, []);
  const getMarketOutcomes = useMemo(makeGetMarketOutcomes, []);
  const { couponType, linkType } = useContext(ConfigContext);
  const hideEmptyEvents = hideEmptyMarketCouponTypes.includes(couponType?.value)
    || hideEmptyMarketCouponTypes.includes(linkType?.value);
  const eventsMarketIds = useSelector(
    (state) => getEventsMarketsIds(state, { eventIds: eventsGroup?.children }),
  );

  const firstEvent = useSelector(
    (state) => getEvent(state, { eventId: eventsGroup?.children?.at(0) }),
  );
  const firstMarketOutcomes = useSelector(
    (state) => getMarketOutcomes(state, { marketId: eventsMarketIds?.at(0) }),
  );

  if (hideEmptyEvents && !eventsMarketIds?.length) {
    return null;
  }

  if (!cardHasMarkets) {
    dispatch(setCardHasMarkets({ cardId }));
  }

  const useAmericanFormat = shouldApplyAmericanFormat(
    firstEvent.americanFormat,
    firstMarketOutcomes,
  );

  return (
    <EventsGroupContainer
      useAmericanFormat={useAmericanFormat}
      eventsGroup={eventsGroup}
    />
  );
};

EventsGroupRedux.propTypes = {
  cardId: PropTypes.string.isRequired,
  cardHasMarkets: PropTypes.bool.isRequired,
  eventsGroup: PropTypes.instanceOf(Object).isRequired,
};

export default EventsGroupRedux;
