import PropTypes from 'prop-types';
import { ScoreHeaders } from 'SportsbookOverview/components/score_headers';

const EventsGroupHeaderView = ({ eventIds, couponHeaders, useAmericanFormat }) => (
  <>
    <ScoreHeaders eventIds={eventIds} />

    <div className={`inplay-coupon-odds-column inplay-headers-count-${couponHeaders?.length}`}>
      {
      (useAmericanFormat ? couponHeaders.toReversed() : couponHeaders).map((couponHeader) => (
        <span key={couponHeader} className="inplay-coupon-odds-label">
          {couponHeader}
        </span>
      ))
    }
    </div>
  </>
);

EventsGroupHeaderView.propTypes = {
  eventIds: PropTypes.instanceOf(Array).isRequired,
  couponHeaders: PropTypes.instanceOf(Array).isRequired,
  useAmericanFormat: PropTypes.bool.isRequired,
};

export default EventsGroupHeaderView;
