/* eslint no-undef: off */
import PropTypes from 'prop-types';
import { linkLocalizer } from 'bv-helpers/locale';
import { t } from 'bv-i18n';
import { Button } from 'bv-components';

const OUTCOMES_TO_DISPLAY = 5;

const MarketView = ({
  eventId, marketId, outcomeIds, eventHref, outcomeComponent,
}) => {
  const Outcome = outcomeComponent;
  const outcomeIdsToDisplay = outcomeIds.slice(0, OUTCOMES_TO_DISPLAY);

  return (
    <>
      {
        outcomeIdsToDisplay.map((outcomeId) => (
          <Outcome outcomeId={outcomeId} eventId={eventId} marketId={marketId} />
        ))
      }
      {
        outcomeIds.length > outcomeIdsToDisplay.length && (
          <div className="inplay-coupon-row inplay-see-all more-button-wrapper">
            <Button href={linkLocalizer(eventHref)} primary>
              { t('javascript.in_play.show_all_markets') }
            </Button>
          </div>
        )
      }
    </>
  );
};

MarketView.propTypes = {
  eventId: PropTypes.number.isRequired,
  marketId: PropTypes.number,
  outcomeIds: PropTypes.instanceOf(Array).isRequired,
  eventHref: PropTypes.string.isRequired,
  outcomeComponent: PropTypes.node.isRequired,
};

MarketView.defaultProps = {
  marketId: null,
};

export default MarketView;
